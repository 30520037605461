/*-- body --*/
  body {
      display: flex;
      min-height: 98vh;
      align-items: center;
      background-color: #f8f9fa;
  }
  /*-- /body --*/
  /*-- Container & Card --*/
  .container {
      padding: 1rem 2.5rem 1.5rem 2.5rem;
      margin: auto;
  }

  .card {
      animation: 0.9s ease-in-out 0s 1 fadeIn;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      border-radius: 5px;
      transition: 0.3s;
  }
  .card-color {
      background: rgb(235,235,235);
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(252,252,252,1) 42%, rgba(255,255,255,1) 100%);
  }
  .card:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  /*-- /Container & Card --*/
  /*-- form --*/
  .form-row {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      padding: 0.5rem;
  }
  .form-row label {
      display: block;
  }
  /*-- /form --*/

  /*-- Follower Result --*/

  ol {
      display: table;
      margin: 0 auto;
      text-align: left;
  }

  ul.no-bullets {
      list-style-type: none; /* Remove bullets */
  }

  .result {
      font-size: 30px;
      text-align: center;
      padding: 5px;
  }

  /*-- Center Text --*/
  .center {
      text-align: center;
  }

  /*-- TextInput --*/
  .input-text {
      font-weight: 500;
      background-color: #f0f0f0;
      padding: 0.6rem 0.3rem 0.6rem 0.6rem;
      border-width: 0;
      border-radius: 0.3rem;
      -webkit-appearance: none;
      outline: none;
  }
  .input-text-block {
      display: block;
  }
  .input-text:focus {
      border-color: 1px solid #444;
  }
  .input-text:disabled {
      opacity: 0.7;
      cursor: auto;
  }
  /*-- /TextInput --*/
  /*-- Button --*/
  .btn-submit, .btn-edit {
      padding: 0.7rem;
      text-decoration: none;
      color: #FFF;
      border: none;
      border-radius: 0.8rem;
      font-weight: bold;
  }
  .btn-submit {
    background-color: #000;
    width: 100%;
  }
  .btn-edit {
    background-color: transparent;
  }
  path {
    fill: #aaa;
  }
  button:hover {
      opacity: 0.9;
  }
  button:focus {
      opacity: 1;
  }
  button:hover, button:focus {
    cursor: pointer;
  }
  button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  /*-- /Button --*/

  /*-- Utils --*/
  .d-flex {
    display: flex;
  }
  .justify-content-end {
    justify-content: end;
    justify-content: flex-end;

  }
  .m-2 {
      margin: 2rem;
  }
  .my-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mx-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .w-100 {
    width: 100%;
  }
  .text-center {
      text-align: center;
  }
  /*-- /Utils --*/
